import { atom, useAtom, useAtomValue } from 'jotai'
import Dialog, { DialogClose } from '@/components/dialog'
import IconClose from '@haiper/icons-svg/icons/outline/crossed-large.svg'
import { auth0SignInAtom, readedNewFeatureKeysAtom } from '@/atoms'
import { cls, setLocalStorage } from '@/utils'
import { useCallback, useEffect, useRef } from 'react'
import { CACHE_KEY_NEW_FEATURE_DIALOG, NEW_FEATURE_MODEL_2_0 } from '@/constants'
import useAmplitude from '@/hooks/useAmplitude'
import { uniq } from 'lodash-es'
import { usePathname } from 'next/navigation'
import Video, { VideoApi } from '@/components/video'
import IconProductHunt from '@haiper/icons-svg/icons/band/producthunt.svg'
import IconX from '@haiper/icons-svg/icons/band/x.svg'
import IconBadgeVerified from '@haiper/icons-svg/icons/outline/badge-verified.svg'
import Button from '@/components/button'
// import IconBubbleAnnotation from '@haiper/icons-svg/icons/outline/bubble-annotation.svg'
import IconArrowTopCircle from '@haiper/icons-svg/icons/outline/arrow-top-circle.svg'
import IconCheck from '@haiper/icons-svg/icons/outline/check-radio.svg'
import Link from '@/components/link'
import Image from '@/components/image'

const showProductHunt = false

const videoUrl = 'https://scontent.haiper.ai/webapp/videos/introduce/haiper-2.0.0.mp4'
const thumbnailUrl = 'https://scontent.haiper.ai/webapp/videos/new-feature/Haiper2.0_Teaser_WIP8.png'

export const model2DialogVisibleAtom = atom(false)

export default function Model2NewFeatureDialog() {
  const [readedNewFeatureKeys, setReadedNewFeatureKeys] = useAtom(readedNewFeatureKeysAtom)
  const isLogin = useAtomValue(auth0SignInAtom)
  const { track } = useAmplitude()

  const [model2DialogVisible, setModel2DialogVisible] = useAtom(model2DialogVisibleAtom)

  const videoApiRef = useRef<VideoApi | null>(null)

  const read = useCallback(() => {
    const newKeys = uniq([...readedNewFeatureKeys, NEW_FEATURE_MODEL_2_0])
    setReadedNewFeatureKeys(newKeys)
    setLocalStorage(CACHE_KEY_NEW_FEATURE_DIALOG, newKeys)
    setModel2DialogVisible(false)
  }, [readedNewFeatureKeys, setReadedNewFeatureKeys, setModel2DialogVisible])

  const handleClose = useCallback(() => {
    read()
  }, [read])

  const viewEventTrackedRef = useRef(false)

  const pathname = usePathname()

  const realOpen =
    model2DialogVisible && isLogin && !pathname.startsWith('/auth') && !pathname.startsWith('/onboarding')

  useEffect(() => {
    if (realOpen && !viewEventTrackedRef.current) {
      track('view:new-feature:model-2.0')
      viewEventTrackedRef.current = true
    }
  }, [realOpen, track])

  const startCreating = useCallback(() => {
    track('click:new-feature:model-2.0:start-creating')
    read()
  }, [track, read])

  const subscribe = useCallback(() => {
    track('click:new-feature:model-2.0:subscribe')
    read()
  }, [track, read])

  const gotoX = useCallback(() => {
    track('click:new-feature:model-2.0:goto-x')
  }, [track])

  const gotoProductHunt = useCallback(() => {
    track('click:new-feature:model-2.0:goto-product-hunt')
  }, [track])

  const onCloseButtonClick = useCallback(() => {
    read()
    track('click:new-feature:model-2.0:close')
  }, [track, read])

  const autoTurnedOnSoundRef = useRef(false)

  const tryTurnOnSound = useCallback(() => {
    if (videoApiRef.current && !autoTurnedOnSoundRef.current) {
      autoTurnedOnSoundRef.current = true
      videoApiRef.current.unmute()
    }
  }, [])

  useEffect(() => {
    window.addEventListener('mousemove', tryTurnOnSound)

    return () => {
      window.removeEventListener('mousemove', tryTurnOnSound)
    }
  }, [tryTurnOnSound])

  return (
    <Dialog
      open={realOpen}
      titleClassName=''
      className='relative gap-0 w-[95vw] max-w-[95vw] md:w-[578px] md:max-w-none p-4 md:p-6 overflow-x-hidden'
      closeClassName='hidden'
      footerClassName='mt-[30px]'
      footer={null}
      onOpenChange={handleClose}
      onMouseMove={tryTurnOnSound}
    >
      <div className='w-[calc(95vw-32px)] md:w-full max-h-[80vh] flex flex-col' onMouseMove={tryTurnOnSound}>
        <div className='w-full overflow-y-auto no-scrollbar'>
          <div className='flex flex-col gap-6' onMouseMove={tryTurnOnSound}>
            {videoUrl ? (
              <Video
                muted
                autoPlay
                loop
                controls
                hasAudioTrack
                preload='auto'
                className='cursor-pointer rounded-md border border-border aspect-video w-full'
                src={videoUrl}
                poster={thumbnailUrl}
                api={videoApiRef}
              />
            ) : (
              <Image
                className='cursor-pointer rounded-md border border-border h-[175px] md:h-[298px]'
                src={thumbnailUrl}
                alt='new-feature-preview'
              />
            )}
            <div className='flex justify-between gap-4 items-center h-7 mb-6'>
              <div className='flex items-center gap-1 text-text font-bold text-heading-2xl leading-7'>
                Introducing Haiper 2.0
              </div>
              <DialogClose asChild autoFocus={false}>
                <Button autoFocus={false} variant='transparent' onClick={onCloseButtonClick}>
                  <IconClose className='size-6 text-icon z-10' />
                </Button>
              </DialogClose>
            </div>
          </div>
          <div className='w-full text-text flex flex-col gap-6 text-body-md tracking-15'>
            <span className='tracking-15'>
              Precision, action, and detail like never before. From jaw-dropping cinematic scenes to high-energy dynamic
              shots, Haiper 2.0 takes your creativity to the next level—effortlessly.
            </span>
            <div className={cls('flex gap-2 items-center w-full', !showProductHunt && 'hidden')}>
              <IconProductHunt className='size-6 text-icon-interactive' />
              <span className='tracking-15 flex-1'>Comment on Product Hunt</span>
              <Button variant='outline' className='w-[120px] h-7 grow-0 shrink-0 p-0' onClick={gotoProductHunt}>
                <Link
                  href='https://www.producthunt.com/posts/haiper-2'
                  target='_blank'
                  className='flex w-full items-center justify-center gap-1'
                >
                  <IconArrowTopCircle className='size-5 text-icon shrink-0' />
                  <span className='px-1 tracking-15'>Upvote</span>
                </Link>
              </Button>
            </div>
            <div className='flex gap-2 items-center w-full'>
              <IconX className='size-6 text-icon-interactive shrink-0' />
              <span className='tracking-15 flex-1'>Comment and Repost on X</span>
              <Button variant='outline' className='w-[120px] h-7 grow-0 shrink-0' onClick={gotoX}>
                <Link
                  href='https://x.com/HaiperGenAI/status/1848384922643546623'
                  target='_blank'
                  className='flex w-full items-center justify-center gap-1'
                >
                  <IconX className='size-5 text-icon shrink-0' />
                  <span className='px-1 tracking-15'>Go to X</span>
                </Link>
              </Button>
            </div>
            <div className='w-full flex flex-col gap-4'>
              <div className='flex gap-2 items-start w-full'>
                <IconBadgeVerified className='size-6 text-icon-interactive shrink-0' />
                <div className='py-1 leading-8 flex-1 flex flex-col gap-4'>
                  <span className='tracking-15 leading-5'>Subscription and Credits 20% off by Oct</span>
                </div>
                <Button variant='outline' className='w-[120px] h-7 grow-0 shrink-0 px-0' onClick={subscribe}>
                  <Link href='/membership' className='flex w-full items-center justify-center gap-1'>
                    <IconCheck className='size-5 shrink-0 text-icon' />
                    <span className='px-1 tracking-15'>Subscribe</span>
                  </Link>
                </Button>
              </div>
              <div className='pl-8'>
                <span className='tracking-15 leading-5'>Promo code:</span>
                <span className='inline bg-surface-subdued rounded-md px-2 py-1 font-bold text-body-lg font-mono ml-2 tracking-[0.8px]'>
                  HAIPER2POINT0
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className='w-full h-10 mt-6 md:mt-8'>
          <div className='absolute inset-x-4 md:inset-x-6 bottom-4 md:bottom-6 flex flex-col md:flex-row items-center gap-3'>
            <Button
              variant='outline'
              className='hidden flex-1 w-full md:w-auto rounded-md shadow-sm'
              onClick={startCreating}
            >
              <Link href='/creations'>Start creating</Link>
            </Button>
            <Button variant='primary' className='flex-1 w-full md:w-auto rounded-md shadow-sm' onClick={subscribe}>
              <Link href='/membership'>
                <span className='text-body-md tracking-15'>Subscribe now and get 20% off</span>
                <span className='text-body-md tracking-15 pl-1 hidden md:inline'>with code</span>
                <span className='text-body-md tracking-15 font-bold pl-1 hidden md:inline'>HAIPER2POINT0</span>
              </Link>
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  )
}
